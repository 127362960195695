<template>
  <div id="covid-page" style="width: calc(100%);" :class="{'mobile': isMobile()}">
    <TitleHelper :title="'Covid-19 data'"></TitleHelper>
    <!-- <div class="explanation" :class="{'open': explanationOpen}" @click="toggleExplanation">
      <div>
        Click here for more information about how this data is generated.
      </div>
      <div :class="{'no-display': !explanationOpen}">
      On 13 August 2020, the UK Government amended the way that death counts attributable to Covid-19 were generated.
      </div>
      <div :class="{'no-display': !explanationOpen}">
      For consistency, this website will attempt to continue to use the same measure that has previously been used. 
      For Wales, Northern Ireland and Scotland, data is obtained from the Coronavirus API (details <a href="https://coronavirus.data.gov.uk/developers-guide">here</a>).
      This data only considers deaths that occured within 28 days of the first laboratory-confirmed positive Covid-19 test as being attributable to the disease.
      </div>
      <div :class="{'no-display': !explanationOpen}">
      The data for England has historically been produced without this "28 day" condition.
      Public Health England continue to publish this full data in an Excel spreadsheet (available <a href="https://www.gov.uk/government/publications/phe-data-series-on-deaths-in-people-with-covid-19-technical-summary">here</a>).
      I will attempt to continue to obtain this information - as long as PHE continue to update that spreadsheet. 
      </div>
      <div :class="{'no-display': !explanationOpen}">
      This change in definition makes a significant difference to figures - for example, the official count for the 24 hours prior to 11 August 2020 was previously a total of <b>77</b> deaths, prior to the change in definition, but now only a figure of <b>20</b> is reported.
      </div>
    </div> -->

<!-- Further note:
From the 8th to 12th October 2020, I was able to retrieve this data from the API.
From the 12th October, the API has daily produced cumDeathsByPublishDate data - which is removed the following day. -->

    <div class="left-panel" style="color: white;">
      <div class="intro">
        <span v-if="displayType == 'daily'">
          In the 24 hours before 
          <span style="color: #820;">{{formattedDay}}</span>
          there were
        </span>
        <span v-if="displayType == '7day'">
          In the 7 days before 
          <span style="color: #820;">{{formattedDay}}</span>
          there were
        </span>
        <span v-if="displayType == 'cumulative'">
          Up until 
          <span style="color: #820;">{{formattedDay}}</span>
          there have been
        </span>
        <span class="switch" @click="changeDisplay">
          <span v-if="displayType == 'daily'">weekly</span>
          <span v-if="displayType == '7day'">cumulative</span>
          <span v-if="displayType == 'cumulative'">daily</span>
        </span>
      </div>

      <div class="person-container">
        <!-- display people -->
        <div v-for='(o,n) in objects' :key="'man-'+n" class="person">
          <font-awesome-icon :icon="man" />
          <span v-if="displayType == 'cumulative'" class="hundred">100</span>
        </div>

        <!-- additional person for cumulative -->
        <div v-if="displayType == 'cumulative' && countdownComplete && currentVal%100 != 0" class="person">
          <font-awesome-icon :icon="man" />
          <span class="hundred extra">{{currentVal%100}}</span>
          <span class="blocker" :style="{ height: blockerHeight + 'px' }"></span>
        </div>

        <span class="number" id="count" v-if="currentVal > 0">{{currentVal}}{{"\xa0"}}</span>
        <span v-if="currentVal == maxVal" class="extro" 
          :class="{'none': !countdownComplete, 'appear': countdownComplete}"
          :style="'position: absolute; padding-right: 8px; top:' + (countLoc.top) +'px'"
          >
          <div style="display:inline-block" :style="'width:'+ (countLoc.right+6) + 'px'"></div>
          deaths reported in the United Kingdom from Covid-19

          <div v-if="displayType == 'daily' || displayType == '7day'"
            class="arrow"
            :class="{'none': !countdownComplete, 'appear': countdownComplete, 
              'up': ((displayType == 'daily' && changeInDaily() > 0) || (displayType == '7day' && changeInWeekly() > 0)),
              'down': ((displayType == 'daily' && changeInDaily() < 0) || (displayType == '7day' && changeInWeekly() < 0)) }">
            <span class="arrow-block" :class="{}">
              <span class="arrow-tick"></span>
            </span>
            <span v-if="displayType == 'daily'">{{changeInDaily() > 0 ? "+" : ""}}{{changeInDaily()}} compared with this day last week</span>
            <span v-if="displayType == '7day'">{{changeInWeekly() > 0 ? "+" : ""}}{{changeInWeekly()}} compared with the previous 7 days</span>
          </div>
        </span>
        <div class="spacer">
        </div>
      </div>
    </div>
    <div class="right-panel">
      <div class="inside-right-panel">
        <div class="right-text update" :class="{'appear': !countdownComplete, 'none': countdownComplete}">
          Source: <a href="https://coronavirus.data.gov.uk">coronavirus.data.gov.uk</a>
          <div>Last updated: {{lastUpdated}}</div>
          <div>Last checked: {{lastChecked}}</div>
          <div v-if="!apiDataStatus">Data not available yet.</div>
        </div>
        <div class="right-text disaster" :class="{'none': !countdownComplete, 'appear': countdownComplete}">
          That's the equivalent of:
          <div class="arrow-down" :class="{'none': !countdownComplete, 'appear': countdownComplete}"></div>
        </div>
        <div class="disaster" :class="{'none': !countdownComplete, 'appear': countdownComplete}">
          <div v-for="(i,n) in paras" :key="'para-'+n" class="right-text">
            {{(maxVal / i.deaths).toFixed(2)}} {{i.name}}
            <div class="arrow-down" :class="{'none': !countdownComplete, 'appear': countdownComplete}"></div>
            
            <div v-if="n == 4" class="phrase-layout">
                HANDS 
                <div class="arrow-right" :class="{'none': !countdownComplete, 'appear': countdownComplete}"></div>
                FACE 
                <div class="arrow-right" :class="{'none': !countdownComplete, 'appear': countdownComplete}"></div>
                SPACE
            </div>

            <div v-if="n == 8" class="phrase-layout">
                GET A TEST
            </div>

            <div v-if="n == 12" class="phrase-layout">
                <span style="width:100%;">SELF ISOLATE</span>
                <span class="smaller">IF YOU HAVE SYMPTOMS</span>
            </div>

            <div v-if="[4,8,12].includes(n)">
              <div class="arrow-down" :class="{'none': !countdownComplete, 'appear': countdownComplete}"></div>
            </div>

          </div>
          <div class="right-text">STAY ALERT</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';
import { faMale } from '@fortawesome/free-solid-svg-icons'

export default {
  name: "Covid19",
  beforeCreate: function() {
    document.body.className = 'covid19';
  },
  data() {
    return {
        currentData: null,
        values: config,
        currentVal: -1,
        objects: [],
        day: null,
        maxVal: null,
        countdownComplete: false,
        displayType: 'daily',
        lastUpdated: null,
        lastChecked: null,
        countLoc: {'top': 0, 'right': 0, 'bottom':0, 'left':0},
        monthLookup : {
          1: 'Jan',
          2: 'Feb',
          3: 'Mar',
          4: 'Apr',
          5: 'May',
          6: 'Jun',
          7: 'Jul',
          8: 'Aug',
          9: 'Sep',
          10: 'Oct',
          11: 'Nov',
          12: 'Dec'
        },
        // explanationOpen: false,
        paras: [
          {'name': 'Bethnal Green crushes', 'deaths': 173},
          {'name': 'Mounts St Helens', 'deaths': 57},
          {'name': 'Lockerbies', 'deaths': 270},
          {'name': 'Aberfanau', 'deaths': 144},
          {'name': 'King\'s Cross fires', 'deaths': 31},
          {'name': 'Belgranos', 'deaths': 323},
          {'name': 'Grenfell Towers', 'deaths': 72},
          {'name': 'Mary Roses', 'deaths': 380},
          {'name': 'Marchionesses', 'deaths': 51},
          {'name': 'Piper Alphas', 'deaths': 167},
          {'name': 'Lampedusa shipwrecks', 'deaths': 359},
          {'name': 'Hillsboroughs', 'deaths': 96},
          {'name': 'Albion Colliery explosions', 'deaths': 290},
          {'name': 'Gordon Riots', 'deaths': 285},
          {'name': 'Ibroxs', 'deaths': 66},
          {'name': 'Tay Bridges', 'deaths': 75},
          {'name': 'Heralds of Free Enterprise', 'deaths': 193},
        ],
        prev7daysEarlier: null,
        prevWeeklyData: null,
        apiDataStatus: false,
    };
  },
  mounted() {
    this.getData();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  components: {
    TitleHelper,
  },
  methods: {
    // toggleExplanation: function() {
    //   this.explanationOpen = !this.explanationOpen;
    // },
    changeDisplay: function() {
      if (this.displayType == 'daily') {
        this.displayType = '7day';
        this.maxVal = this.weeklyData['totalDeaths'];
        this.currentVal = 0;
        this.objects = [];
      } else if (this.displayType == '7day') {
        this.displayType = 'cumulative';
        this.maxVal = this.currentData['deathCumulative'];
        this.currentVal = 0;
        this.objects = [];
      } else {
        this.displayType = 'daily';
        this.maxVal = this.currentData['deathsThatDay'];
        this.currentVal = 0;
        this.objects = [];
      }
    },
    getData: function() {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/covid/";
      axios
        .get(baseUrl+'latest',body,config)
        .then(response => {
            this.currentData = response["data"][0];
            this.maxVal = this.currentData['deathsThatDay'];
            this.currentVal = 0;
            this.day = this.currentData['date'];
        })
        .catch(error => {
            this.errorValue = error;
        })
      axios
        .get(baseUrl+'new-last-updated',body,config)
        .then(response => {
            let lu = new Date(response['data']['lastUpdated']);
            let d = this.doubleFigures(lu.getDate())
            let m = this.doubleFigures(lu.getMonth()+1)
            let h = this.doubleFigures(lu.getHours())
            let mn = this.doubleFigures(lu.getMinutes())
            let s = this.doubleFigures(lu.getSeconds())
            this.lastUpdated = d + '/' + m + '/' + lu.getFullYear() + ' ' + h + ':' + mn + ':' + s;
        })
        .catch(error => {
            this.errorValue = error;
        })
      axios
        .get(baseUrl+'new-last-checked',body,config)
        .then(response => {
            let lc = new Date(response['data']['lastChecked']);
            let d = this.doubleFigures(lc.getDate())
            let m = this.doubleFigures(lc.getMonth()+1)
            let h = this.doubleFigures(lc.getHours())
            let mn = this.doubleFigures(lc.getMinutes())
            let s = this.doubleFigures(lc.getSeconds())
            this.lastChecked = d + '/' + m + '/' + lc.getFullYear() + ' ' + h + ':' + mn + ':' + s;
            if('APIData' in response['data'] && response['data']['APIData']) {
              this.apiDataStatus = true;
            }
        })
        .catch(error => {
            this.errorValue = error;
        })
      axios
        .get(baseUrl+'7day',body,config)
        .then(response => {
            this.weeklyData = response['data'][0];
        })
        .catch(error => {
            this.errorValue = error;
        })
      axios
        .get(baseUrl+'prev7day',body,config)
        .then(response => {
            this.prevWeeklyData = parseInt(response['data'][0]['totalDeaths']);
        })
        .catch(error => {
            this.errorValue = error;
        })
      axios
        .get(baseUrl+'7daysearlier',body,config)
        .then(response => {
          this.prev7daysEarlier = parseInt(response['data'][0]['deathsThatDay']);
        })
        .catch(error => {
            this.errorValue = error;
        })
    },
    isMobile: function() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    setBlockerHeight: function() {
    },
    onResize() {
      if (this.countdownComplete) {
        var el = document.getElementById('count');
        this.countLoc = {'top': el.offsetTop, 'left': el.offsetLeft, 'bottom': el.offsetTop+el.offsetHeight, 'right': el.offsetLeft+el.offsetWidth-22};
        if (this.isMobile()) {
          this.countLoc['right'] = this.countLoc['right'] + 6;
        }
      }
    },
    doubleFigures(num) {
      if (num < 10) {
        return '0'+num;
      } else {
        return num;
      }
    },
    changeInDaily() {
      if (this.currentData && 'deathsThatDay' in this.currentData && this.prev7daysEarlier) {
        return parseInt(this.currentData['deathsThatDay']) - parseInt(this.prev7daysEarlier);
      }
    },
    changeInWeekly() {
      if (this.weeklyData && this.prevWeeklyData) {
        return parseInt(this.weeklyData['totalDeaths']) - parseInt(this.prevWeeklyData);
      }
    }
  },
  computed: {
    blockerHeight() {
      let fullHeight = this.isMobile() ? 21 : 44;
      return ((100-(this.maxVal%100))/100) * fullHeight;
    },
    man() {
      return faMale;
    },
    formattedDay() {
      let d = new Date(this.day);
      let dd = d.getUTCDate();
      let mm = this.monthLookup[d.getUTCMonth()+1];
      let yy = d.getUTCFullYear();
      let hh = d.getUTCHours();
      return dd + ' ' + mm + ' ' + yy + ' ' + hh + ':00';
    }
  },
  watch: {
    currentVal: function(val) {
      var p = (((val+1/this.maxVal)/this.maxVal)*2)-1
      var scaler = (1/this.maxVal) * 40000;
      var sp = (scaler *p*p*p*p*p*p)
      if (val >= 0 && val < this.maxVal) {
        this.countdownComplete = false;
        setTimeout(() => {
          var add = 1;
          if (this.displayType == 'cumulative') {
            if ((10/sp) >= 100) {
              add = 100;
            } else {
              add = Math.ceil(10/sp);
            }
            var inc = (Math.floor((this.currentVal+add)/100) - Math.floor(this.currentVal/100));
            
            if (inc > 0 && (this.currentVal+add <= this.maxVal)) {
              for (var i = 0; i < inc; i++) {
                this.objects.push('x');
              }
            }
          }

          if (this.displayType == 'daily' || this.displayType == '7day') {
            this.objects.push('x');
          }

          if ((this.currentVal + add) > this.maxVal) {
            this.currentVal = parseInt(this.maxVal);
          } else {
            this.currentVal += add;
          }

          
          if (this.currentVal >= this.maxVal) {
            this.countdownComplete = true;
            setTimeout(() => {
              var el = document.getElementById('count');
              this.countLoc = {'top': el.offsetTop, 'left': el.offsetLeft, 'bottom': el.offsetTop+el.offsetHeight, 'right': el.offsetLeft+el.offsetWidth-22};
              if (this.isMobile()) {
                this.countLoc['right'] = this.countLoc['right'] + 6;
              }
            }, 10)
          }
        }, sp );
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@font-face {
    font-family: 'heading_font';
    src: url('./fonts/coluna_rounded-webfont.woff2') format('woff2'),
         url('./fonts/coluna_rounded-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gov_alert';
    src: url('./fonts/FreeSet W01 Cond Demi Bold.woff2') format('woff2'),
         url('./fonts/FreeSet W01 Cond Demi Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#covid-page {
  height: calc(100vh);
  background-color: black;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #3333dd;
  overflow: hidden;
  display: flex;
}

div.left-panel {
  font-family: 'heading_font';
  overflow: scroll;
  width: 50%;
  padding: 8px;
  height: calc(100vh);
  position: relative;
}

#covid-page.mobile div.left-panel {
  top: 0px;
}

div.right-panel {
  font-family: 'gov_alert';
  text-transform: uppercase;
  overflow: scroll;
  text-align: center;
  width: 50%;
  color: black;
  background: repeating-linear-gradient(
    45deg,
    yellow,
    yellow 20px,
    green 20px,
    green 40px
  );
  position: relative;
  height: calc(100vh);
}

#covid-page.mobile div.right-panel {
  height: calc(100vh - 110px);
  top: 0px;
  position: relative;
}

div.inside-right-panel {
  position: absolute;
  left: 20px;
  top: 20px;
  background: yellow;
  height: calc(100vh - 48px);
  width: calc(50vw - 60px);
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

#covid-page.mobile div.inside-right-panel {
  height: calc(100vh - 163px);
  top: 20px;
  position: relative;
}

div.intro, span.extro {
  font-size: 50px;
  line-height: 45px;
}

#covid-page.mobile div.intro,
#covid-page.mobile span.extro {
  font-size: 20px;
  line-height: 18px;
}

div.right-text {
  font-size: 30px;
  line-height: 28px;
}

div.right-text span.smaller {
  font-size: 15px;
  line-height: 13px;
}

#covid-page.mobile div.right-text {
  font-size: 18px;
  line-height: 16px;
}

#covid-page.mobile div.right-text span.smaller {
  font-size: 10px;
  line-height: 8px;
}

div.arrow {
  padding-top: 40px;
}

span.extro, div.arrow {
  color: white;
}

div.arrow.none {
  display: none;
}

span.extro.appear, div.arrow {
  animation: fade-in 3s ease-in;
  color: white;
}

div.update {
  font-size: 20px;
  line-height: 18px;
  color: yellow;
}

#covid-page.mobile div.update {
  font-size: 12px;
  line-height: 10px;
}

div.update div {
  padding: 10px 0 0 0;
}

div.update a {
  color: inherit;
}

div.update.none {
  display: none;
}

div.update.appear {
  color: yellow;
  animation: fade-out-update 6s ease-out;
}

div.disaster.none {
  display: none;
}

div.disaster.appear {
  color: black;
  animation: fade-in-disaster 3s ease-in;
}

div.disaster.appear div:nth-child(3) {
  animation-delay: 5s;
}

@keyframes fade-in {
  0% {
    color: #000;
  }
  100% {
    color: #fff;
  }
}


@keyframes fade-in-disaster {
  0% {
    color: yellow;
  }
  100% {
    color: black;
  }
}

@keyframes fade-out-update {
  0% {
    color: black;
  }
  100% {
    color: yellow;
  }
}

div.person-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

div.person {
  margin: 5px 2px;
  font-size: 44px;
  color: #820;
  line-height: 32px;
  position: relative;
  bottom: 8px;
}

#covid-page.mobile div.person {
  font-size: 21px;
  line-height: 15px;
}

span.number {
  font-size: 50px;
  position: relative;
  left: 10px;
  line-height: 45px;
  bottom: -6px;
  margin-bottom: 5px;
}

#covid-page.mobile span.number {
  font-size: 20px;
  line-height: 18px;
  left: 4px;
  bottom: -3px;
  margin-bottom: 2px;
}

div.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  
  border-top: 20px solid transparent;
  margin: 10px auto;
}

div.arrow-down.appear {
  animation: fade-in-arrow 3s ease-in;
  border-top: 20px solid green;
  margin: 10px auto;
}

.mobile div.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  
  border-left: 6px solid transparent;
  margin: 0 4px;
}

.mobile div.arrow-right.appear {
  animation: fade-in-mob-right-arrow 3s ease-in;
  border-left: 6px solid green;
  margin: 0 4px;
}

div.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  
  border-left: 12px solid transparent;
  margin: 0 20px;
}

div.arrow-right.appear {
  animation: fade-in-right-arrow 3s ease-in;
  border-left: 12px solid green;
  margin: 0 20px;
}

div.phrase-layout {
  display: inline-flex;
  flex-flow: wrap;
  justify-content: center;
}

@keyframes fade-in-arrow {
  0% {
    border-top: 20px solid transparent;
  }
  100% {
    border-top: 20px solid green;
  }
}

@keyframes fade-in-right-arrow {
  0% {
    border-left: 12px solid transparent;
  }
  100% {
    border-left: 12px solid green;
  }
}

@keyframes fade-in-mob-right-arrow {
  0% {
    border-left: 6px solid transparent;
  }
  100% {
    border-left: 6px solid green;
  }
}

body {
  margin: 0px;
}

span.hundred {
  position: absolute;
  font-size: x-small;
  color: #fdd;
  bottom: 6px;
  left: 3px;
}

#covid-page.mobile span.hundred {
  font-size: 5px;
  bottom: 3px;
  left: 1px;
}

span.hundred.extra {
  left: 5px;
  z-index: 2;
}

#covid-page.mobile span.hundred.extra {
  left: 2px;
}

span.blocker {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 17px;
  background-color: black;
  z-index: 1;
}

#covid-page.mobile span.blocker {
  width: 8px;
}

span.switch {
  background-color: #820;
  padding: 3px 5px 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  text-shadow: 2px 2px black;
  bottom: 5px;
  position: relative;
}

#covid-page.mobile span.switch {
  font-size: 8px;
  text-shadow: 1px 1px black;
  padding: 2px 3px 0 3px;
}

span.arrow-block {
  animation: trend-arrow 3s ease-in;
  width: 30px; 
  height: 30px; 
  display: inline-flex; 
  position: relative; 
  left: 10px; 
  margin-right: 20px;
  position: relative;
}

.mobile span.arrow-block {
  width: 10px; 
  height: 10px; 
  display: inline-flex; 
  position: relative; 
  left: 5px; 
  margin-right: 10px;
  position: relative;
}

.arrow.up span.arrow-block {
  background-color: red;
  bottom: 0px;
}

.arrow.down span.arrow-block {
  background-color: green;
  bottom: 7.5px;
}

span.arrow-tick {
  animation: trend-arrow-tick 1s ease-in;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute; 
  left: -5px;
}

.mobile span.arrow-tick {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    left: -3px;
}

.arrow.up span.arrow-tick {
  border-bottom: 15px solid red; 
  top: -15px; 
}

.mobile .arrow.up span.arrow-tick {
  border-bottom: 5px solid red; 
  top: -5px; 
}

.arrow.down span.arrow-tick {
  border-top: 15px solid green; 
  top: 30px;
}

.mobile .arrow.down span.arrow-tick {
  border-top: 5px solid green; 
  top: 10px;
}

@keyframes trend-arrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes trend-arrow-tick {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* 
div.explanation {
  font-family: sans-serif;
  font-size: 14px;
  padding: 3px;
  border: 2px dotted;
  background-color: #880000;
  max-height: 44px;
  overflow: hidden;
  -webkit-transition: max-height 2s ease-out;
  transition: max-height 2s ease-out;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  margin: 3px;
  width: calc(50% - 8px);
  z-index: 3;
  color: white;
}

div.explanation a {
  text-decoration: none;
  color: #aaa;
}

div.explanation a:hover {
  text-decoration: underline;
}


.mobile div.explanation {
  font-size: 10px;
  max-height: 30px;
}

div.explanation.open {
  max-height: calc(50%);
}

div.explanation div {
  transition: color 2s ease-in-out;
}

div.explanation.open div {
  transition: color 0.2s ease-in-out;
}

.mobile div.explanation.open {
  max-height: calc(80%);
}

div.explanation > div {
  padding: 2px 0;
} */

div.no-display {
  color: #880000;
}

div.spacer {
  height: 150px;
  padding: 30px;
  width: 100%;
}

</style>

